<script>

    import { fade } from 'svelte/transition'
    import { replace, location } from 'svelte-spa-router'

    import Quickview from '../$components/quickview.svelte'

    let show = false

    function showView(view) {
        replace(`#/${view}`);
    }

    function onClose() {
        show = false
    }

</script>

<style>
    .title, .subtitle {
        color: #4E3596;
    }

    .item {
        padding: 16px;
        color: black !important;
    }

    .item:hover, .item.active {
        color: #4E3596;
        background-color: whitesmoke;
    }

    .item.active {
        color:#4E3596;
        background-color: whitesmoke;
    }

    .menu {
        color:white !important;
    }

    .menu:hover {
        color: #F57D2A;
    }

    .menu .active * {
        color: #F57D2A;
    }
</style>

<span on:click={() => show = true} class="icon-text pointer menu">
    <span class="icon"><i class="fas fa-bars"></i></span>
    <span>Más</span>
</span>

<Quickview title="Menú" bind:show>
    <div class="items-center">
        <div class="title">Más secciones</div>
    </div>
    <br>
    <div on:click={() => showView('posts')} class="item pointer" class:active={$location === '/posts'}>
        <span class="icon is-medium">
            <i class="fas fa-bullhorn"></i>
        </span>
        <small>FORO</small>
    </div>
    <div on:click={() => showView('courses')} class="item pointer" class:active={$location === '/courses'}>
        <span class="icon is-medium">
            <i class="fas fa-graduation-cap"></i>
        </span>
        <small>CURSOS</small>
    </div>
    <div on:click={() => showView('tutorials')} class="item pointer" class:active={$location === '/tutorials'}>
        <span class="icon is-medium">
            <i class="fas fa-film"></i>
        </span>
        <small>TUTORIALES</small>
    </div>
    <div on:click={() => showView('fulfillment')} class="item pointer" class:active={$location === '/fulfillment'}>
        <span class="icon is-medium">
            <i class="fas fa-store"></i>
        </span>
        <small>FULFILLMENT</small>
    </div>
    <div on:click={() => showView('tracings')} class="item pointer" class:active={$location === '/tracings'}>
        <span class="icon is-medium">
            <i class="fas fa-info-circle"></i>
        </span>
        <small>SEGUIMIENTO</small>
    </div>
    <div on:click={() => showView('settings')} class="item pointer" class:active={$location === '/settings'}>
        <span class="icon is-medium">
            <i class="fas fa-user-cog"></i>
        </span>
        <small>AJUSTES</small>
    </div>
    <br>
    <div class="items-center">
        <div on:click={onClose} class="subtitle pointer" in:fade>CERRAR</div>
    </div>
</Quickview>