<script>

    import { toast } from 'bulma-toast'
    import { sengridsStore } from '../stores'

    import SengridsService from '../$services/sengrid.service'

    import Button from '../$components/button.svelte'
    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Select from '../$components/select.svelte'
    import Form from '../$components/form.svelte'
    
    let data = {}
    let loading = false
    let showMessage = false

    async function sendClientInformation() {

        loading = true
        const resp = await SengridsService.sendClientInformation(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        showMessage = true
        sengridsStore.append(resp.data)

        toast({message: 'Información enviada', type: 'is-success'})
    }

</script>

<style>

    .hero {
        background: linear-gradient(90deg, #136C4D, #B41C29);
        /* background: linear-gradient(70deg, #4E3596, #F57D2A); */
    }

    .title, .label, .label-switch, a {
        color: whitesmoke;
    }

</style>

<section class="hero is-fullheight">
    <div class="hero-body">
        <div class="container">
            {#if !showMessage}
                <Form on:submit={sendClientInformation} {loading} >
                    <div class="columns">
                        <div class="column">
                            <InputText bind:value={data.name} label="Nombre" icon="user" placeholder="p. ej. Ana Roldán" whitesmoke={true} />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Correo electronico</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-at"></i></span>
                                    <input bind:value={data.email} type="email" class="input" placeholder="p. ej. ejemplo@gmail.com" required>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Teléfono</label>
                                <div class="control has-icons-left">
                                    <span class="icon"><i class="fas fa-phone"></i></span>
                                    <input bind:value={data.phone} type="text" minlength="10" class="input" placeholder="p. ej. 2228945123" required>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <Select bind:value={data.shipments} whitesmoke={true} label="¿Cual es tu volumen mensual de envíos?" icon="list" options={[
                                {value: '5000-10000', text: '5 000 - 10 000'},
                                {value: '1000-5000', text: '1 000 - 5 000'},
                                {value: '500-1000', text: '500 - 1 000'},
                                {value: '100-500', text: '100 - 500'},
                                {value: '<100', text: 'Menos de 100'},
                            ]} />
                        </div>
                        <div class="column">
                            <InputText bind:value={data.products} label="¿Qué producto envías?" icon="box-open" placeholder="p. ej. Fundas de celular" minlength="10" whitesmoke={true} />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <InputNumber bind:value={data.expenditure} label="¿Gasto mensual en logística/envíos?" icon="dollar-sign" placeholder="p. ej. 25000" min="1" whitesmoke={true} />
                        </div>
                        <div class="column">
                            <InputText bind:value={data.better} label="¿Qué quieres mejorar en tu logística?" icon="clipboard-list" placeholder="p. ej. El tiempo de entrega" whitesmoke={true} />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <input bind:checked={data.checked} class="is-checkradio is-primary" id="mainCheck" type="checkbox" required>
                                <label for="mainCheck"><a href="files/aviso.pdf" target="_blank">Acepto el aviso de privacidad</a></label>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <input bind:checked={data.offers} id="offers" type="checkbox" name="offers" class="switch">
                                <label class="label-switch" for="offers">Quiero recibir la información sobre las ofertas especiales a mi correo</label>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" text="Solicitar invitación" icon="paper-plane" color="primary" fullwidth={true} />
                </Form>
            {:else}
                <div class="center">
                    <div class="title">¡Gracias!</div>
                    <br>
                    <div class="title">Nos pondremos en contacto contigo lo más pronto posible</div>
                </div>
            {/if}
        </div>
    </div>
</section>