<script>

    import Moment from 'moment'
    import { toast } from 'bulma-toast'
    import { onMount, createEventDispatcher } from 'svelte'
    import { replace, location } from "svelte-spa-router"
    import { userStore, popupStore, notifyStore } from '../stores'

    import BotsService from '../$services/bots.service'
    import ShipmentsService from '../$services/shipments.service'
    import OrdersService from '../$services/orders.service'
    import StorageService from '../$services/storage.service'

    import InputNumber from '../$components/input.number.svelte'
    import InputText from '../$components/input.text.svelte'
    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'
    import Button from '../$components/button.svelte'

    import LocationDetails from '../locations/component.location.details.svelte'
    import PackageDetails from '../packages/component.package.details.svelte'
    import Merchandises from '../merchandises/component.merchandises.list.svelte'

    const { HOST_APP } = _CONSTANTS_
    const { BRAND } = _CONSTANTS_

    const dispatch = createEventDispatcher()

    export let store = null
    export let order = null
    export let pickup = null
    export let delivery = null
    export let ipackage = null
    export let quotation = null
    export let shipmentType = 'national'

    let done = (order && store) ? 'Órdenes' : 'Cotizador'
    let storeName = store ? store.name : ''
    let guideNumber = order ? order.number : ''

    let element = null

    onMount(() => {
        document.getElementById("app").scroll(0, 0)
    })

    let loading = false
    let data = {}

    let tag = ''
    let documentFormat = $userStore.email === 'accounts@directoship.com' ? '6x4' : 'pdf'
    let shipment = null
    let referenceInput = null
    let merchandises = []
    let isCOD = false
    let codAmount = 0
    let isOccurs = false

    let error = null

    async function createShipment() {

        if(!merchandises.length)
            return toast({message: 'Se requerie registrar la mercancia que se envía', type: 'is-danger'})

        data = {
            tag,
            pickup,
            delivery,
            documentFormat,
            packageContent: ipackage.content,
            merchandises,
            isOccurs,
            codAmount,
            token: quotation.token
        }

        if(store && order) {
            data.storeId = store._id
            data.orderId = order._id
            data.remoteId = order.remoteId
        }

        if(order) {
            const isSuccess = StorageService.getItem(`ORDER_${(order || {}).number}_SHOP_${data.storeId}_SUCCESS`)
            if(isSuccess) return
        }

        loading = true

        let resp = null

        if(shipmentType === 'international')
            resp = await ShipmentsService.createShipmentInternational(data)

        if(shipmentType === 'national')
            resp = await ShipmentsService.createShipmentNational(data)

        loading = false

        if(!resp.success) {
            error = JSON.stringify(resp.error, null, 4)
            return toast({message: resp.error.message, type: 'is-danger'})
        }

        if(order) {
            const orderData = resp.data
            StorageService.setItem(`ORDER_${orderData.number}_SHOP_${orderData.storeId}_SUCCESS`, orderData.shipmentId)
        }

        shipment = resp.data.shipment

        notifyStore.set('orders_updated')
    }

    async function createOrder() {

        const data = {
            pickup,
            delivery,
            package: ipackage
        }

        loading = true
        const resp = await OrdersService.createOrder(data)
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        toast({message: 'Orden guardada', type: 'is-success'})
        replace('#/orders')
    }

    function onCopy() {
        referenceInput.select()
        referenceInput.setSelectionRange(0, 99999)
        document.execCommand("copy")
        toast({message: 'Link copiado', type: 'is-success'})
    }

    function setStep(step) {
        dispatch('step', step)
    }

    async function createFile() {

        const data = {
                user: $userStore.email,
                tag,
                pickup,
                delivery,
                documentFormat,
                package: ipackage,
                merchandises,
                quotation: `${quotation.provider}, ${quotation.service}, $${quotation.finalPrice}`,
                other: `${done}, ${storeName}, ${guideNumber}`
        }

        await BotsService.sendOrderError(data)

        let fileName = `datos.guia${ Moment().format() }.txt`

        let stringData = `
            USUARIO
            ------------------------------------
            Nombre: ${$userStore.name}
            Correo: ${$userStore.email}
            
            ORIGEN
            ------------------------------------
            Contacto: ${pickup.contact}
            Empresa: ${pickup.corporate}
            Teléfono: ${pickup.phone}
            Correo: ${pickup.email}

            Codigo_Postal: ${pickup.zipcode}
            Estado: ${pickup.state}
            Ciudad: ${pickup.city}
            Municipio: ${pickup.municipality}
            Colonia: ${pickup.colony}
            Calle: ${pickup.street}
            Número_Exterior: ${pickup.number}
            Número_Interior: ${pickup.apartment}
            Referencias: ${pickup.references}
            
            DESTINO
            ------------------------------------
            Contacto: ${delivery.contact}
            Empresa: ${delivery.corporate}
            Teléfono: ${delivery.phone}
            Correo: ${delivery.email}

            Codigo_Postal: ${delivery.zipcode}
            Estado: ${delivery.state}
            Ciudad: ${delivery.city}
            Municipio: ${delivery.municipality}
            Colonia: ${delivery.colony}
            Calle: ${delivery.street}
            Número_Exterior: ${delivery.number}
            Número_Interior: ${delivery.apartment}
            Referencias: ${delivery.references}
            
            PAQUETE
            ------------------------------------
            Ancho: ${ipackage.width}
            Alto: ${ipackage.height}
            Largo: ${ipackage.length}
            Peso: ${ipackage.weight}
            Volumétrico: ${quotation.package.volumetric}
            Valor: ${ipackage.value}
            Contenido: ${ipackage.content}

            MERCANCIA
            ------------------------------------
            ${ listMerchandise() }

            SERVICIO
            ------------------------------------
            Proveedor: ${quotation.provider}
            Servicio: ${quotation.service}
            Precio: ${quotation.finalPrice}
            Formato: ${documentFormat}

            DATA
            ------------------------------------
            Realizado_Desde: ${done}
            Nombre_Tienda: ${storeName}
            Número_Órden: ${guideNumber}


            RESPONSE
            ------------------------------------
            ${error}
        `
        const blob = new Blob([stringData], {type: 'text/plain'})

        element.download = fileName
        element.href = window.URL.createObjectURL(blob)
        element.click()
    }

    function listMerchandise() {

        let str = ''

        merchandises.forEach(item => {
            str += `
                Producto: ${ item.product.name }
                Producto: ${ item.product.code }
                Items: ${ item.items }
                Peso: ${ item.weight }
                Unidad: ${ item.unit.name }
                Unidad: ${ item.unit.code }
                Valor: ${ item.value }
                Descripción: ${ item.description }
                ------------------------------------
            `
        })

        return str
    }

</script>

<Spinner {loading} />

{#if !loading}
    {#if shipment}
        <div class="columns">
            <div class="column">
                <div class="title">Guía generada</div>
                <div class="columns">
                    <div class="column">
                        <div>Número de guía</div>
                        <div><b>{shipment.guide}</b></div>
                    </div>
                    <div class="column">
                        <div>Código shipper</div>
                        <div>{shipment.code}</div>
                    </div>
                    <div class="column">
                        <div>Guía PDF</div>
                        <div>
                            <a href="{shipment.document}" target="_blank">
                                <span class="icon">
                                    <i class="fas fa-download"></i>
                                </span>
                                <span>Descargar aquí</span>
                            </a>
                        </div>
                    </div>
                    {#if shipmentType === 'international' && shipment.provider === 'FedEx'}
                        <div class="column">
                            <div>Factura comercial</div>
                            <div>
                                <a href="/files/fedex_commercial_invoice.pdf" target="_blank">
                                    <span class="icon">
                                        <i class="fas fa-download"></i>
                                    </span>
                                    <span>Descargar aquí</span>
                                </a>
                            </div>
                        </div>
                    {/if}
                </div>
                {#if shipmentType === 'international' && shipment.provider === 'FedEx'}
                    <div class="columns">
                        <div class="column">
                            <article class="message">
                                <div class="message-body">
                                    ❗ Recuerda descargar y llenar el <strong>formato de factura comercial</strong> y anexar la <strong>factura</strong> que avala el costo de los productos
                                </div>
                            </article>
                        </div>
                    </div>
                {/if}
                <div class="columns">
                    <div class="column">
                        <div>Puedes compartir el link de rastreo</div>
                        <div class="items-space-around">
                            <input bind:this={referenceInput} value={`${HOST_APP}/#/trackings/${shipment.code}`} type="text" class="input space-right" readonly>
                            <button on:click={onCopy} class="button" data-tooltip="Copiar link">
                                <span class="icon"><i class="fas fa-copy"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="items-end">
                    {#if $location === '/quotations'}
                        <button on:click={() => setStep('zipcodes')} type="button" class="button space-right">
                            <span class="icon"><i class="fas fa-truck"></i></span>
                            <span>Nuevo envío</span>
                        </button>
                    {/if}
                    <button on:click={() => replace('#/shipments')} type="button" class="button is-primary">
                        <span class="icon"><i class="fas fa-shipping-fast"></i></span>
                        <span>Ver mis envíos</span>
                    </button>
                </div>
            </div>
        </div>
    {/if}

    {#if !shipment}
        <div class="columns">
            <div class="column">
                <div class="title">Origen</div>
                <LocationDetails location={ pickup }/>
                <div class="items-end">
                    <button on:click={() => setStep('pickup')} class="button">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
            <div class="column">
                <div class="title">Destino</div>
                <LocationDetails location={ delivery }/>
                <div class="items-end">
                    <button on:click={() => setStep('delivery')} class="button">
                        <span class="icon"><i class="fas fa-edit"></i></span>
                        <span>Editar</span>
                    </button>
                </div>
            </div>
        </div>
        <hr>
        <div class="columns">
            <div class="column">
                <div class="title">Paquete</div>
                <PackageDetails ipackage={ ipackage }/>
                <div class="items-end">
                    {#if $location === '/quotations'}
                        <button on:click={() => setStep('zipcodes')} class="button">
                            <span class="icon"><i class="fas fa-edit"></i></span>
                            <span>Editar</span>
                        </button>
                    {:else}
                        <button on:click={() => setStep('package')} class="button">
                            <span class="icon"><i class="fas fa-edit"></i></span>
                            <span>Editar</span>
                        </button>
                    {/if}
                </div>
            </div>
        </div>
        <hr>
        {#if $userStore.email === 'admin@myshipper.com.mx' && quotation.provider === '99Minutos'}
            <div class="columns">
                <div class="column">
                    <div class="title">Pago contra entrega</div>
                    <Switch bind:checked={isCOD} label="Agregar pago contra entrega" />
                    <br>
                    {#if isCOD}
                        <InputNumber bind:value={codAmount} label="Valor de la mercancia" icon="dollar-sign" />
                    {/if}
                </div>
            </div>
            <hr>
        {/if}
        <div class="columns">
            <div class="column">
                <div class="title">Mercancia</div>
                <Merchandises bind:merchandises={merchandises} packageId={ipackage._id} maxWeight={ quotation.volumetric } />
            </div>
        </div>
        <hr>
        <div class="columns">
            <div class="column">
                <div class="items-center col">
                    <img class="provider-image" src="images/{quotation.provider.replace(' Partner', '')}.svg" alt="{quotation.provider}">
                    
                    <div class="title is-3">{quotation.service}</div>
                    <div class="subtitle is-4"><b>${quotation.finalPrice}</b></div>

                    {#if quotation.provider === 'DHL' && quotation.volumetric === 5}
                        <div class="notification is-danger">
                            <div class="title">Importante</div>
                            <b>SI TU PAQUETE PESA MÁS DE 5KG, POR EJEMPLO: 5.1KG, 5.5KG. ¡CUIDADO! ESTO GENERA UN CARGO POR SOBREPESO QUE SE COBRARA A SU CUENTA.</b> <br>
                            <b>RECOMENDAMOS REVISAR LAS MEDIDAS Y PESO DE SU PAQUETE PARA EVITAR UN CARGO POR HASTA $1,000 MXN.</b>
                        </div>
                    {/if}
                    
                    <div class="tags">
                        <span class="tag">{quotation.days} Días aproximado</span>
                        {#if !quotation.isDeliveryHome}
                            <span class="tag is-danger">Sin entrega a domicilio</span>
                        {/if}
                        {#if quotation.isCoverageCost}
                            <span class="tag is-danger">Zona extendida</span>
                        {/if}
                        {#if quotation.paymentType === 'prepaid'}
                            <span class="tag is-primary">Guía prepago {quotation.prepaidShipments}/{quotation.prepaidShipmentsTotal}</span>
                        {/if}
                        {#if quotation.credentialType != BRAND}
                            <span class="tag is-primary">{quotation.credentialAlias}</span>
                        {/if}
                        {#if quotation.information}
                            <span class="tag is-primary icon-text" style="color: white;">
                                <span>{quotation.information}&nbsp;&nbsp;</span>
                                <span on:click={() => popupStore.set('frequency_popup')} class="class-icon"><i class="fas fa-question-circle" style="color: whitesmoke; cursor: pointer;"></i></span>
                            </span>
                        {/if}
                        {#if quotation.credentialType === 'user' && quotation.insurancePrice}
                            <span class="tag is-danger">El costo del seguro deberá ser consultado con la paquetería</span>
                        {/if}
                    </div>
                    <div class="items-end">
                        <button on:click={() => setStep('quotations')} class="button">
                            <span class="icon"><i class="fas fa-edit"></i></span>
                            <span>Editar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        {#if quotation.provider === 'Paquetexpress'}
            <div class="columns">
                <div class="column">
                    <div class="title is-4">Ocurre</div>
                    <Switch bind:checked={ isOccurs } label="Entrega ocurre" />
                </div>
            </div>
            <hr>
        {/if}
        <div class="column">
            {#if !shipment && !loading}
                <form on:submit|preventDefault={createShipment}>
                    <div class="columns">
                        <div class="column">
                            <br>
                            <InputText bind:value={ ipackage.content } label="Descripción general de los productos" icon="tag" required />
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="field">
                                <span class="icon-text" style="color: #363636; font-size: 1rem; font-weight: 700;">
                                    <span>Agregar un identificador personal </span>
                                    <span on:click={() => popupStore.set('identifier_popup')} class="class-icon"><i class="fas fa-question-circle" style="color: #F57D2A; cursor: pointer;"></i></span>
                                </span>
                                <div class="control has-icons-left" style="margin-top: 0.5em;">
                                    <span class="icon"><i class="fas fa-tag"></i></span>
                                    <input bind:value={tag} type="text" class="input" placeholder="Identificador personal">
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label for="" class="label">Formato de guía</label>
                                <div class="control">
                                    <div class="select">
                                        <select bind:value={documentFormat}>
                                            <option value="pdf">PDF</option>
                                            <option value="6x4">6x4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <article class="message">
                                <div class="message-body">
                                    ❗A partir de este momento, usted dispone de <b>7 días habiles</b> para ocupar sus guias o en cualquier circunstancia poder cancelar las mismas.
                                </div>
                            </article>
                        </div>
                    </div>
                    <div class="columns">
                        {#if merchandises.length}
                            <div class="column">
                                <a bind:this={element} href="/" style="display: none;">.</a>
                                <Button on:click={() => createFile()} text="Descargar datos de la guía" fullwidth={ true } icon="file-alt" />
                            </div>
                        {/if}
                        {#if $location === '/quotations'}
                            <div class="column">
                                <button on:click={createOrder} type="button" class="button is-fullwidth">
                                    <span class="icon"><i class="fas fa-heart"></i></span>
                                    <span>Guardar como orden</span>
                                </button>
                            </div>
                        {/if}
                        <div class="column">
                            <button type="submit" class="button is-primary is-fullwidth">
                                <span class="icon"><i class="fas fa-paper-plane"></i></span>
                                <span>Generar guía</span>
                            </button>
                        </div>
                    </div>
                </form>
            {/if}
        </div>
    {/if}
{/if}